import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import FilterSet from 'components/Explorer/models/filter_set';
import InputField from './InputField';

class AuthorFilter extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  render() {
    return (
      <InputField
        filterSet={this.props.filterSet}
        history={this.props.history}
        initialSearch={this.props.initialSearch}
        name="author_id"
        id="filter_author_id"
        type="authors"
        label={I18n.t('Explorer.AdvancedSearch.fields.authors.title')}
        helpUrl={I18n.t('Explorer.AdvancedSearch.help_uri')}
        helpTip={I18n.t('Explorer.AdvancedSearch.fields.authors.description')}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName="Verified Author"
        loadingMessage={I18n.t('Explorer.AdvancedSearch.fields.authors.loading')}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.authors.placeholder')}
        queryMethod="authorNames"
      />
    );
  }
}

export default withRouter(AuthorFilter);
