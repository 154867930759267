import './styles';

import React from 'react';
import PropTypes from 'prop-types';

const PROFILE_TO_ICON = {
  msm: 'msm',
  blog: 'blog',
  policy: 'policy',
  tw: 'tweet',
  sw: 'weibo',
  fb: 'fbwall',
  gplus: 'gplus',
  guideline: 'guideline',
  linkedin: 'linkedin',
  rdt: 'rdt'
};

class MentionSourceProfileIcon extends React.Component {
  static propTypes = {
    profileType: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className={`MentionSourceProfileIcon ${PROFILE_TO_ICON[this.props.profileType]}`}>
        <div className="icon"></div>
      </div>
    );
  }
}

export default MentionSourceProfileIcon;
