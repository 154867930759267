import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { pick } from 'lodash-es';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import DateSearchFields from './DateSearchFields';
import InstitutionalToggle from './InstitutionalToggle';
import InstitutionSearchFields from './InstitutionSearchFields';
import IntegrationsSearchFields from './IntegrationsSearchFields';
import OutputSearchFields from './OutputSearchFields';
import PublicationSearchFields from './PublicationSearchFields';
import SearchTerm from 'models/SearchTerm';

import { defaultSearchScope } from '../util/default_search_scope';

const DIALOG_CLOSED_EVENT = 'Advanced Search: Dialogue Closed';

class AdvancedSearch extends React.Component {
  containerElement = null;

  static propTypes = {
    history: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    initialSearchTerm: PropTypes.instanceOf(SearchTerm).isRequired
  };

  constructor(props) {
    super(props);

    this.query = new Query(this.props.history);
    this.filterSet = new FilterSet(this.query.filters);
    this.containerRef = React.createRef();
    this.previouslyFocused = document.activeElement;
  }

  componentDidMount() {
    Analytics.trackEvent('Advanced Search: Dialogue Opened', {
      scope: this.analyticsScopeName(this.currentScope)
    });

    window.addEventListener('keydown', this.closeOnEscape);

    const container = this.containerRef.current;

    // Only call `showModal` if the browser supports the <dialog/> element to
    // reveal the form on render. The form will be revealed automatically in
    // older browsers that don't support <dialog/>.
    if ('showModal' in container) {
      container.showModal();
    }

    const firstInputField = container.querySelector('input');
    firstInputField.focus();
  }

  componentWillUnmount() {
    this.query.cleanup();
    window.removeEventListener('keydown', this.closeOnEscape);
  }

  closeOnEscape = (event) => {
    if (event.key === 'Escape') {
      this.hide();
      Analytics.trackEvent(DIALOG_CLOSED_EVENT, { method: 'Escape Key Press' });
    }
  };

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <dialog ref={this.containerRef}>
        <form method="dialog" className="Explorer-Form Explorer-AdvancedSearch" onSubmit={this.onSubmit}>
          <ReactTooltip id="HelpLink" effect="solid" />

          <div className="Explorer-AdvancedSearch-content">
            <h2 className="Explorer-AdvancedSearch-title">{I18n.t('Explorer.AdvancedSearch.title')}</h2>

            <button type="button" className="Explorer-AdvancedSearch-close-button" onClick={this.close}>
              <span className="label">{I18n.t('Explorer.AdvancedSearch.close')}</span>
            </button>

            {window.current_user.institution_integrated ? this.institutionContent : this.generalContent}
          </div>

          <div className="Explorer-AdvancedSearch-buttons">
            {this.cancelButton}

            <div>
              {this.clearButton}
              {this.searchButton}
            </div>
          </div>
        </form>
      </dialog>
    );
  }

  get currentScope() {
    return this.filterSet.get('scope') || defaultSearchScope(window.current_user);
  }

  get institutionContent() {
    return (
      <div className="Explorer-AdvancedSearch-fields">
        <div className="Explorer-AdvancedSearch-column">
          <InstitutionSearchFields filterSet={this.filterSet} initialSearchTerm={this.props.initialSearchTerm} />
          <PublicationSearchFields filterSet={this.filterSet} initialSearchTerm={this.props.initialSearchTerm} />
          <IntegrationsSearchFields filterSet={this.filterSet} />
        </div>

        <div className="Explorer-AdvancedSearch-column">
          <InstitutionalToggle
            scope={this.filterSet.get('scope') || defaultSearchScope(window.current_user)}
            changeScope={this.changeScope}
          />
          <OutputSearchFields filterSet={this.filterSet} initialSearchTerm={this.props.initialSearchTerm} />
          <DateSearchFields filterSet={this.filterSet} />
        </div>
      </div>
    );
  }

  get generalContent() {
    return (
      <div className="Explorer-AdvancedSearch-fields">
        <div className="Explorer-AdvancedSearch-column">
          <OutputSearchFields filterSet={this.filterSet} initialSearchTerm={this.props.initialSearchTerm} />
          <IntegrationsSearchFields filterSet={this.filterSet} />
        </div>

        <div className="Explorer-AdvancedSearch-column">
          <PublicationSearchFields filterSet={this.filterSet} initialSearchTerm={this.props.initialSearchTerm} />
          <DateSearchFields filterSet={this.filterSet} />
        </div>
      </div>
    );
  }

  get clearButton() {
    return (
      <button type="button" className="clear" onClick={this.clearFields}>
        {I18n.t('Explorer.AdvancedSearch.clear')}
      </button>
    );
  }

  get searchButton() {
    return (
      <button type="submit" className="execute">
        {I18n.t('Explorer.AdvancedSearch.search')}
      </button>
    );
  }

  get cancelButton() {
    return (
      <button type="button" className="cancel" onClick={this.cancel}>
        {I18n.t('Explorer.AdvancedSearch.cancel')}
      </button>
    );
  }

  // Events
  //////////////////////////////////////////////////////////////////////////////

  onSubmit = (e) => {
    e.preventDefault();
    this.applySearch();
  };

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  hide = () => {
    const container = this.containerRef.current;
    if ('close' in container) {
      container.close();
    }

    this.props.onClose();
    this.previouslyFocused.focus();
  };

  close = () => {
    this.hide();
    Analytics.trackEvent(DIALOG_CLOSED_EVENT, { method: 'Close Button' });
  };

  cancel = () => {
    this.hide();
    Analytics.trackEvent(DIALOG_CLOSED_EVENT, { method: 'Cancel Button' });
  };

  applySearch = () => {
    this.hide();
    Analytics.trackEvent('Advanced Search: Search Applied');

    this.props.history.push(this.query.withFilters(this.filterSet.populatedFilters).location);
  };

  clearFields = () => {
    Analytics.trackEvent('Advanced Search: All-Fields Cleared');

    const possibleMentionFilterNames = Array.prototype.concat(
        window.site_config.mentionFilterParams,
        window.site_config.mentionFilterParams.map((name) => name + '[]')
      ),
      preservedMentionsFilters = pick(this.query.cloneParams(), possibleMentionFilterNames);

    const default_search_scope = { scope: defaultSearchScope(window.current_user) };
    const query = this.query.withFilters({ ...preservedMentionsFilters, ...default_search_scope });
    this.filterSet = new FilterSet(query.filters);

    this.forceUpdate();
  };

  changeScope = (newScope) => {
    Analytics.trackEvent('Advanced Search: Scope Changed', {
      scope: this.analyticsScopeName(newScope)
    });

    this.filterSet.set('scope', newScope);
    this.forceUpdate();
  };

  analyticsScopeName = (scope) => {
    switch (scope) {
      case 'institution':
        return 'institution';
      case 'all':
        return 'full';
      default:
        return 'undefined';
    }
  };
}

export default withRouter(AdvancedSearch);
