import { useEffect, useState } from 'react';
import requestFeatureFlags from './requestFeatureFlags';

const useFeatureFlag = (flagName) => {
  const [featureFlags, setFeatureFlags] = useState([]);

  useEffect(() => {
    const loadFeatureFlags = async () => {
      const flags = await requestFeatureFlags();
      setFeatureFlags(flags);
    };

    loadFeatureFlags();
  }, []);

  return featureFlags.includes(flagName);
};

export default useFeatureFlag;
