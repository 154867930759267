import Query from 'components/Explorer/models/query';

const requestFeatureFlags = async () => {
  const response = await fetch(`${Query.BASE_JSON_PATH}/features`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });

  const featureFlags = await response.json();

  if (!response.ok || !Array.isArray(featureFlags)) {
    throw new Error('Bad response from Flipper');
  }

  return featureFlags;
};

export default requestFeatureFlags;
