class Publisher {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = result.name;
  }
}

class Author {
  constructor(result) {
    this.id = result.id;
    this.first_name = result.first_name;
    this.last_name = result.last_name;
    this.departments = result.departments;
    this.description = `${this.first_name} ${this.last_name}`;
  }
}

class Department {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = this.name;
  }
}

class DoiPrefix {
  constructor(result) {
    this.id = result.id;
    this.name = result.id;
    this.description = result.id;
  }
}

class FieldOfResearchCode {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = `${result.id} ${result.name}`;
  }
}

class Country {
  constructor(result) {
    this.id = result.code;
    this.name = result.name;
    this.description = result.name;
  }
}

class MentionSource {
  constructor(result) {
    this.id = result.id;
    this.type = result.type;

    // type: post_type
    this.postType = result.post_type;

    // type: mention_source
    this.imageURL = result.image_url;
    this.profileType = result.profile_type;
    this.name = result.name;

    // type: mention_source_type
    this.mentionSourceType = result.mention_source_type;
  }

  // eslint-disable-next-line getter-return
  get description() {
    switch (this.type) {
      case 'mention_source':
        return this.name;
      case 'post_type':
        return this.postTypeDescription;
      case 'mention_source_type':
        return this.mentionSourceTypeDescription;
    }
  }

  // eslint-disable-next-line getter-return
  get title() {
    switch (this.type) {
      case 'mention_source':
        return I18n.t(this.profileType, {
          scope: 'mentions.filter.mention_source_types'
        });
      case 'post_type':
        return this.postTypeDescription;
      case 'mention_source_type':
        return this.mentionSourceTypeDescription;
    }
  }

  get postTypeDescription() {
    return I18n.t(this.postType, {
      scope: 'Explorer.Mentions.FilterPanel.autocomplete_mention_source_post_types'
    });
  }

  get mentionSourceTypeDescription() {
    return I18n.t(this.mentionSourceType, {
      scope: 'Explorer.MentionSources.FilterPanel.autocomplete_source_types'
    });
  }
}

class HandlePrefix {
  constructor(result) {
    this.id = result.id;
    this.description = result.id;
  }
}

class Journal {
  constructor(result) {
    this.id = result.id;
    this.title = result.title;
    this.description = result.title;
    this.issns = result.issns;
  }
}

class Affiliation {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = result.name;
  }
}

class Funder {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = result.name;
  }
}

class Grant {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = result.name;
    this.projectName = result.project_name;
  }
}

class SustainableDevelopmentGoal {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = `${result.id} ${result.name}`;
  }
}

export default {
  Publisher,
  DoiPrefix,
  Author,
  Department,
  Country,
  MentionSource,
  HandlePrefix,
  Journal,
  Affiliation,
  FieldOfResearchCode,
  Funder,
  Grant,
  SustainableDevelopmentGoal
};
